import React, { useEffect, useState } from 'react';
import Loader from './../../commonComponents/activityIndicator/activityIndicator';
import { Dimensions, Image, ImageBackground, Text, TouchableOpacity, View } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import { useDispatch, useSelector } from 'react-redux';
import { showToaster } from '../../redux/actions/toast';
import {
  ButtonLinkBox,
  ButtonLinkText,
  ButtonLinkView,
  FormView,
  WideButtonView,
  WideButtonWrapper,
} from './myAccountStyles';
import WideButton from '../../commonComponents/wideButton';
import CustomTextInput from '../../commonComponents/customTextInput/customTextInput';
import DropDownPicker from '../../commonComponents/DropDownPicker';
import OpenMarket from './openMarket';
import { LogoutApi, addRentalInfo, changePassword, contactUs, deleteRentalInfo, editRentalInfo, getProfile, referFriend, updateNotiSettings, updateProfile } from '../../redux/actions/auth';
import getPlatformType from '../../helpers/getPlatform';
import { EmailValidation, MobileValidation } from '../../helpers/validation';
import NotificationList from '../notificationSettings/NotificationList';
import { getDigitalVerificationStatus } from '../openFlow/helpers';
import { COLORS } from '../../constants/colorPallete';
import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import { BoxShadow, TabContainer, Wrapper } from '../idVerification/styles';
import { LinearGradient } from 'expo-linear-gradient';
import { openFlow } from '../../constants/openFlo';
import CustomPopUp from '../../commonComponents/customPopUp/customPopUp';
import { navigateAction, replaceAction } from '../../helpers/navigationHelpers';
import { Typography } from '../openFlow/style';
import { validateEmail } from '../../helpers/regex';
import ShareMedia from '../../commonComponents/shareMedia/shareMedia';
import { ShareOptions } from '../propertyDetails/styles';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton/index.native';
import { getImage } from '../../../openbrix-design-system/utils/getScreenType';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import { useTheme } from '@emotion/react';
import MyAccountPanel from '../../../openbrix-design-system/ui/molecules/Account/MyAccountPanel/MyAccountPanel';
import MyAccountCard from '../../../openbrix-design-system/ui/molecules/Account/MyAccount/MyAccount';
import { useRoute } from '@react-navigation/native';
import MyInvoiceCard from '../../../openbrix-design-system/ui/molecules/Account/MyInvoices/MyInvoices';
import ReferAFriendCard from '../../../openbrix-design-system/ui/molecules/Account/ReferAFriend/ReferAFriendCard';
import AlertCard from '../../../openbrix-design-system/ui/molecules/Account/AlertCard/AlertCard';
import SavedPropertiesCard from '../../../openbrix-design-system/ui/molecules/Account/SavedPropertiesCard/SavedPropertiesCard';
import ContactUsCard from '../../../openbrix-design-system/ui/molecules/Account/ContactUsCard/ContactUsCard';
import ChangePasswordCard from '../../../openbrix-design-system/ui/molecules/Account/ChangePasswordCard/ChangePasswordCard';
import SettingsCards from '../../../openbrix-design-system/ui/molecules/Account/Settings/SettingsCard';
import { navigate } from '../../navigation/RootNavigation';
import MyRentalInfo from '../../../openbrix-design-system/ui/molecules/Account/MyRentalInfor';


const layoutType = getPlatformType();

const ChildScreen = (props) => {
  const dispatch = useDispatch();
  const rental_profile = useSelector((state) => state.authReducer?.rental_profile?.data);

  const [deletePopUpIsVisible, setDeletePopUpIsVisible] = useState(false);
  const [showShare,setShowShare]=useState(false);
  const {params} =useRoute();
  const {id,data,idStatus,useremail,navigation}=params;
  const profileLoading = useSelector((state) => state.authReducer.profileLoading);
  const profile = useSelector((state) => state.authReducer.profile);
  const {colors}=useTheme();
console.log("useremail",useremail)
  const onProfileUpdate=(item)=>{
    dispatch(
        updateProfile({
          user: {
            first_name: item.firstName,
            last_name: item.lastName,
            contact_number: item.contact,
            user_id:data.user_id,
            user_type: 0,
            title: item.title,
          },
        })
      );
  }
  const onReferAFriend=(email)=>{
    dispatch(referFriend({email:email },()=>{
      }))
  }
  const onContactUs=(data)=>{
    dispatch(
        contactUs({
            email: data.email,
            topic: data.subject,
            name: data.firstName,
            message: data.message,
        })
      );
  }
  const onCopyPress=(e)=>{
    navigator.clipboard.writeText(e);
    dispatch({ type: types.SHOW_ALERT, alertType: "success", text: "ID Copied" });
}
const onChangePassword = (data) => {
  dispatch(changePassword(
      {
          newPassword: data.newPassword,
          currentPassword: data.currentPassword,
      },
      props?.navigate,
  ))
}
const onUpdateSettings = (data) => {
  let req = {
      property_notification: data.property_notification,
      marketplace_notification: data.marketplace_notification,
      rental_notification: data.rental_notification,
      maintenance_notification: data.maintenance_notification,
      openflow_notification: data.openflow_notification,
  };
  dispatch(updateNotiSettings(req))
}
const onAddRentalInfo = (data) => {
  let req = {
      "rental_property_name": data?.rental_property_name,
      "rental_amount": data.rental_amount,
      "deposit_amount": data.deposit_amount,
      "property_address": data.property_address,
      "rental_start_date":new Date(data.rental_start_date).toISOString(),
      "expected_end_date": new Date(data.expected_end_date).toISOString(),
      "property_post_code": data.property_post_code,
    };
  dispatch(addRentalInfo(req))
}
const onEditRentalInfo = (data) => {
  let req = {
      "rental_property_name": data?.rental_property_name,
      "rental_amount": data.rental_amount,
      "deposit_amount": data.deposit_amount,
      "property_address": data.property_address,
      "rental_start_date":new Date(data.rental_start_date).toISOString(),
      "expected_end_date": new Date(data.expected_end_date).toISOString(),
      "property_post_code": data.property_post_code,
      "rental_profile_id":data?.rental_profile_id
    };
    console.log("reqreq000",data)
    console.log("reqreq1111",req)
  dispatch(editRentalInfo(req))
}
const onDelereRentalInfo = (id) => {
  dispatch(deleteRentalInfo(id))
}
const [rentalData, setRentalData] = useState({});
useEffect(() => {
  if (rental_profile&&rental_profile.length) {
      setRentalData(rental_profile);
  }else{
    setRentalData([]);
  }
}, [rental_profile]);
  return (
    <BackGroundGenerator props={props} isTLYF>
      {/* <Loader loading={profileLoading} /> */}
      <ImageBackground
        source={require('../../../openbrix-design-system/images/banner/web/my-account.png')}
        style={{
          width: '100%',
          height: '246px',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
        resizeMode="cover"
      >
        <ODSButton
          rIcon={'TB-Arrow-Left'}
          restStyle={{ marginTop: 20, marginLeft:layoutType=="web"?60:20 }}
          type="secondary"
          disabled={false}
          onPress={() => replaceAction(props?.navigation, 'My Account')}
        >
         My Account
        </ODSButton>
      </ImageBackground>
     <View style={{width:layoutType=="phone"?'100%':700,top:-60,alignSelf:'center'}}>
    {id=='profile' && <MyAccountCard onCopyPress={onCopyPress} onPress={(e)=>onProfileUpdate(e)} data={data} idStatus={idStatus}/>}
    {id=='invoice' && <MyInvoiceCard>
                        <NotificationList />
                    </MyInvoiceCard>}
                    {id == "My_Rental_Information" && 
                    <MyRentalInfo
                        data={rentalData}
                        onAddRentalInfo={(data) => {
                            onAddRentalInfo(data)
                        }}
                        onDeletePress={(id)=>{
                            onDelereRentalInfo(id)
                        }}
                        onEditPress={(data)=>{
                            onEditRentalInfo(data)
                        }}
                    />}
    {id=="saved" && <SavedPropertiesCard />}
    {id=="alerts" && <AlertCard />}
    {id=="refer" && <ReferAFriendCard 
       useremail={useremail}
    shareComponent={()=><ShareMedia
                              // shareUrl={`Refer a friend https://play.google.com/store/apps/details?id=com.tlyfe&hl=en&gl=US`}
                              shareUrl={`Refer a Friend\nCheck it out!\n\nhttps://play.google.com/store/apps/details?id=com.tlyfe&hl=en&gl=US`}
                           
                              emailSubject={
                                "Refer a friend"
                              }
                              emailBody={`Refer a friend https://play.google.com/store/apps/details?id=com.tlyfe&hl=en&gl=US`}
                            />} onSend={(email)=>onReferAFriend(email)}/>}
    {id=="contact-us" && <ContactUsCard data={data} onSend={(data)=>onContactUs(data)}/>}
    {id == 'settings' && 
                     <View
                     style={{
                         width: "100%",
                         alignSelf: 'center',
                         backgroundColor: 'white',
                         padding: 24,
                         borderRadius: 12,
                         shadowColor: '#101828',
                         shadowOffset: { width: 0, height: 1 },
                         shadowOpacity: 0.1,
                         shadowRadius: 4,
                         elevation: 2,
                     }}>
                    <SettingsCards data={{
                        property_notification: profile?.data?.appUser?.property_notification,
                        marketplace_notification: profile?.data?.appUser?.marketplace_notification,
                        maintenance_notification: profile?.data?.appUser?.maintenance_notification,
                        rental_notification: profile?.data?.appUser?.rental_notification,
                        openflow_notification: profile?.data?.appUser?.openflow_notification,
                    }} onSave={(data) => onUpdateSettings(data)} onClose={() => { replaceAction(props?.navigation, 'My Account')}} />
                    </View>
                    }
                     {id == "change-password" && <View
                        style={{
                            width: "100%",
                            alignSelf: 'center',
                            backgroundColor: 'white',
                            padding: 24,
                            borderRadius: 12,
                            shadowColor: '#101828',
                            shadowOffset: { width: 0, height: 1 },
                            shadowOpacity: 0.1,
                            shadowRadius: 4,
                            elevation: 2,
                        }}>
                        <ChangePasswordCard onChangePassword={(data) => onChangePassword(data)} onClose={() => { replaceAction(props?.navigation, 'My Account')}} />

                    </View>}
     </View>




    </BackGroundGenerator>
  );
};
export default ChildScreen;
