import React, { useState } from 'react';
import { Text, StyleSheet, TouchableOpacity, View, ImageBackground, FlatList } from 'react-native';

import { useTheme } from '@emotion/react';
import ODSHomeBoxTile from '../../atoms/ODSTiles/HomeboxTile';
import ODSTenancyTile from '../../atoms/ODSTiles/TenancyTile';
import getScreenType from '../../../utils/getScreenType';
import ODSTiles from '../../atoms/ODSTiles';
import ODSInsuranceTile from '../../atoms/ODSTiles/InsuranceTile';
import ODSRentReadyTile from '../../atoms/ODSTiles/RentReadyTile';

const ODSDashboardTiles = ({
  tiles,
  navigationRoutes,
  navigation,
  rentReadyData,
  renderTenancyTile,
}) => {
  const { colors } = useTheme();
  const layout = getScreenType();

  const getItem = (id) => {
    const item = navigationRoutes.find((item) => item.id === id);
    return item;
  };
  const renderODSTiles = ({ item }) => {
    let componentToRender;

    switch (item.card) {
      case 'BillingBetter':
        componentToRender =
          // <Background
          //   source={{ uri: backGroundImage }}
          //   height={"263px"}
          //   width={"344px"}
          //   resizeMode="cover"
          //   imageStyle={{ borderRadius: 10 }}
          //   padding={"0px"}
          // >
          //   <BillingBetter profile={profile?.data} backGroundImage={backGroundImage}/>
          // </Background>
          null;
        break;
      case 'OpenFlow':
        // componentToRender = (renderTeanantMoveIn());
        break;
      case 'Insurance':
        componentToRender =
          // <Hoverable
          //   onHoverIn={() => handleHoverIn("LandingInsurance")}
          //   onHoverOut={() => handleHoverOut("LandingInsurance")}
          // >
          //   {renderFcc(backGroundImage)}
          // </Hoverable>
          null;
        break;
      case 'MoneyCenter':
        // componentToRender = (
        //   <Hoverable
        //     onHoverIn={() => handleHoverIn("Money Centre")}
        //     onHoverOut={() => handleHoverOut("Money Centre")}
        //   >
        //     {renderZopa(backGroundImage)}
        //   </Hoverable>
        // );
        null;
        break;
      case 'MyDocuments':
        componentToRender = (
          <ODSTiles
            source={require('../../../images/dashboard/tiles/md.png')}
            title={'My Documents & Share Centre'}
            restStyle={{
              marginVertical: layout == 'phone' ? 10 : 15,
              marginHorizontal: layout == 'phone' ? 0 : 15,
            }}
            subtitle="View and share your documents with agents and landlords in a secure way."
            btnTheme={colors.main.purple}
            onPress={() => {
              navigation.navigate(getItem('MyDocuments').route);
            }}
          />
        );
        break;
      case 'CreditBuilder':
        componentToRender = (
          <ODSTiles
            isRight
            source={require('../../../images/dashboard/tiles/cb.png')}
            title={'Credit Builder'}
            restStyle={{
              marginVertical: layout == 'phone' ? 10 : 15,
              marginHorizontal: layout == 'phone' ? 0 : 15,
            }}
            subtitle="Report your rent payments to help boost your credit score."
            btnTheme={colors.main.green}
            // status={getItem('CreditBuilder').status}
            onPress={() => {
              navigation.navigate(getItem('CreditBuilder').route);
            }}
          />
        );
        break;
      case 'TenancyDeposit':
        componentToRender = (
          <ODSTiles
            source={require('../../../images/dashboard/tiles/td.png')}
            title={'Tenancy Deposit'}
            restStyle={{
              marginVertical: layout == 'phone' ? 10 : 15,
              marginHorizontal: layout == 'phone' ? 0 : 15,
            }}
            subtitle="View and Manage your tenancy deposit with the TDS."
            btnTheme={colors.main.pink}
            onPress={() => {
              navigation.navigate(getItem('TenancyDeposit').route);
            }}
          />
        );
        break;
      case 'PreQualification':
        componentToRender = (
          <View
            style={{
              marginVertical: layout == 'phone' ? 10 : 15,
              marginHorizontal: layout == 'phone' ? 0 : 15,
            }}>
            <ODSRentReadyTile
              onPress={() => navigation.navigate(getItem('PreQualification').route)}
              rentReadyData={rentReadyData}
            />
          </View>
        );
        break;
      case 'IDVerification':
        componentToRender = (
          <ODSTiles
            isRight
            restStyle={{
              marginVertical: layout == 'phone' ? 10 : 15,
              marginHorizontal: layout == 'phone' ? 0 : 15,
            }}
            source={require('../../../images/dashboard/tiles/id.png')}
            title={'ID Verification'}
            subtitle="Unlock a world of convenience with tlyfe’s verified digital ID, accepted at over 30,000 outlets across the UK."
            // status={
            //   tenant_info?.occupation || tenant_info?.marital_status ? 'Completed' : 'Not Started'
            // }
            status={getItem('IDVerification').status}
            btnTheme={colors.main.purple}
            onPress={() => {
              navigation.navigate(getItem('IDVerification').route);
            }}
          />
        );
        break;
      case 'Rewards':
        componentToRender = (
          <ODSTiles
            restStyle={{
              marginVertical: layout == 'phone' ? 10 : 15,
              marginHorizontal: layout == 'phone' ? 0 : 15,
            }}
            source={require('../../../images/dashboard/tiles/tr.png')}
            title={'tlyfe Rewards'}
            subtitle="Access hundreds of savings with National Retailers, including Waitrose, M&S, Ikea, Sainsburys, Pizza Express and many, many more!"
            btnTheme={colors.main.pink}
            onPress={() => {
              navigation.navigate(getItem('Rewards').route);
            }}
          />
        );
        break;
      case 'InfoHub':
        componentToRender = (
          <ODSTiles
            restStyle={{ marginVertical: 10 }}
            isRight
            source={require('../../../images/dashboard/tiles/ih.png')}
            title={'Info Hub'}
            subtitle="The latest news & videos for tenants in the private rental sector."
            btnTheme={colors.main.purple}
            onPress={() => {
              // navigation.navigate(getItem("InfoHub"))
            }}
          />
        );
        null;
        break;

      default:
        componentToRender = null;
        break;
    }
    return componentToRender;
  };

  return (
    <View style={{ flex: 1, paddingBottom: 60 }}>
      {tiles.length > 0 && tiles[0]['card'] != 'OpenFlow' ? (
        <ODSHomeBoxTile navigation={navigation} fullWidth />
      ) : (
        <View
          style={{
            flexDirection: layout == 'phone' ? 'column' : 'row',
            gap: layout != 'phone' && 15,
            justifyContent: 'center',
          }}>
          <ODSHomeBoxTile />
          {layout == 'phone' && <View style={{ height: 15 }} />}
          {renderTenancyTile && renderTenancyTile}
        </View>
      )}
      <View style={{ height: 30 }} />
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors.other['background-green'],
          width: '100%',
        }}>
        <FlatList
          data={tiles}
          renderItem={renderODSTiles}
          keyExtractor={(item, index) => index}
          contentContainerStyle={{
            maxHeight: layout == 'web' ? 760 : layout == 'tab' ? 1100 : 'auto',
            flexWrap: layout != 'phone' ? 'wrap' : 'nowrap',
            paddingVertical: layout == 'phone' ? 32 : 32,
            paddingBottom: 60,
            paddingHorizontal: layout == 'phone' ? 12 : layout == 'tab' ? 0 : 0,
          }}
        />
      </View>
      <ODSInsuranceTile onPress={() => navigation.navigate(getItem('Insurance').route)} />
    </View>
  );
};

// const styles = (theme) =>
//     StyleSheet.create({
//         containerStyle: {
//             width: getScreenType() == "phone" ? "100%" : 360,
//             borderRadius: 12,
//             shadowColor: '#101828',
//             shadowOffset: { width: 0, height: 1 },
//             shadowOpacity: 0.1,
//             shadowRadius: 4,
//             elevation: 2,
//             backgroundColor: theme.neutral[100],
//             justifyContent: 'center',
//             alignItems: 'center',
//         },
//         button: {
//             ,
//             height: 28,
//             // paddingVertical: 12,
//             paddingHorizontal: 10,
//             justifyContent: 'center',
//             backgroundColor: theme.feedback.error[700],
//             alignItems: 'center',
//             borderRadius: 1000,
//             shadowColor: '#101828',
//             shadowOffset: { width: 0, height: 1 },
//             shadowOpacity: 0.1,
//             shadowRadius: 4,
//             elevation: 2,
//             flexDirection: 'row',

//         }
//     });

export default ODSDashboardTiles;
