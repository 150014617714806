import React, { useState } from 'react';
import {
  Pressable,
  Text,
  StyleSheet,
  TouchableWithoutFeedback,
  TouchableOpacity,
  View,
  ImageBackground,
  Image,
} from 'react-native';


import getScreenType from '../../../utils/getScreenType';
import ODSBadges from '../../atoms/ODSBadges';
import { ODSText } from '../../atoms/ODSText';
import ODSButton from '../../atoms/ODSButton';
import { getTokens } from '../../../design-tokens/color-tokens';

const RentSetting = ({ restStyle, onClick, btnTheme }) => {
  const themeObj = getTokens('light').colors;

  return (
    <View style={[styles(themeObj).containerStyle, restStyle]}>
      <ODSText marginTop={6} color="black" type={'h5'}>
        Rent Ready Settings
      </ODSText>
      <ODSText marginTop={6} color="black" type={'sm'}>
        Select the documents to share with your agent
      </ODSText>
      <View style={{ marginTop: 20, alignSelf: 'flex-end' }}>
        <View style={{ flex: 0.45 }}>
          <ODSButton
            restStyle={{ width: 200 }}
            type="primary"
            disabled={false}
            themeColor={btnTheme}
            onPress={onClick}>
            Edit Settings
          </ODSButton>
        </View>
      </View>
    </View>
  );
};

const styles = (theme) =>
  StyleSheet.create({
    containerStyle: {
      width: '100%',
      height: 170,
      borderRadius: 12,
      shadowColor: '#101828',
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 0.1,
      shadowRadius: 4,
      elevation: 2,
      backgroundColor: theme.neutral['white'],
      padding: 20,
      borderWidth: 1,
      borderColor: '#D0D6DD',
    },
  });

export default RentSetting;
