import React, { useEffect, useState } from 'react';
import { Text, StyleSheet, TouchableOpacity, View, Platform, Image } from 'react-native';
import { useTheme } from '@emotion/react';
import getScreenType from '../../../../utils/getScreenType';
import { ODSIcon } from '../../../atoms/ODSIcon';
import { ODSText } from '../../../atoms/ODSText';
import { ODSInput } from '../../../atoms/ODSInput';
import ODSBadges from '../../../atoms/ODSBadges';
import ODSCombobox from '../../../atoms/ODSComboBox';
import ODSButton from '../../../atoms/ODSButton';
// import SelectDropdown from 'react-native-select-dropdown';
import { Icon } from '@ui-kitten/components';
import { BaseStyle } from 'constants/themes/style';
import MyRentalInfoModal from './MyRentalInfoModal';
import moment from 'moment';

const titles = [
  {
    value: 'Mr',
    label: 'Mr',
  },
  {
    value: 'Mrs',
    label: 'Mrs',
  },
  {
    value: 'Miss',
    label: 'Miss',
  },
  {
    value: 'Ms',
    label: 'Ms',
  },
  {
    value: 'Dr',
    label: 'Dr',
  },
  {
    value: 'Mx',
    label: 'Mx',
  },
  {
    value: 'N/A',
    label: 'N/A',
  },
];

const MyRentalInfo = ({
  onDeletePress,
  data,
  onAddRentalInfo,
  onEditPress,
}) => {
  const { colors } = useTheme();
  const [isEdit, setEdit] = useState(false);
  const layout = getScreenType();
  const [form, setForm] = useState([]);
  const [errors, setErrors] = useState({});
  const [modal, setModal] = useState({ type: "", visible: false });



  useEffect(() => {
    setForm(data);
  }, [data]);
  console.log("formformform", form)
  const validateForm = () => {
    let valid = true;
    let errors = {};
    if (form.contact && (!/^\d{8,}$/.test(form.contact))) {
      errors.contact = 'Invalid contact number.';
      valid = false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!form.email || !emailRegex.test(form.email)) {
      errors.email = 'Please enter a valid email address.';
      valid = false;
    }
    setErrors(errors);
    return valid;
  };
  const renderLabels = (label, value) => {
    return (
      <View style={{ marginBottom: 12 }}>
        <ODSText color="black" type={'button'}>
          {label ? label : ""}
        </ODSText>
        <ODSText type="sm" fontSize={20} paddingVertical={4} >
          {value ? value : ""}
        </ODSText>
      </View>
    )
  }
  const RenderAccorDian = (props) => {
    const [showMore, setShowMore] = React.useState(false);
    const { isImage, headerName, imageStyle, imageURI, children, isEdit = false, onEdit = () => { }, isDelete = false, onDelete = () => { } } = props
    return (
      <View style={{ borderRadius: 10, borderColor: colors.other["border-neutral"], borderWidth: 1, padding: 20 }}>
        <TouchableOpacity
          onPress={() => {
            setShowMore(!showMore)
          }}
          style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", }}>
          {!isImage ? <ODSText type='h6' marginLeft={12} color={colors.text.primary}> {headerName ? headerName : ""}</ODSText> :
            <Image resizeMode='contain' source={{ uri: isImage }} style={[{ width: 100, height: 34 }, imageStyle]} />}
          <ODSIcon
            style={{ marginRight: 20 }}
            color={colors.main.blue[400]}
            icon={showMore ? 'Frame-3' : 'Icon'}
            size={26}
          />
        </TouchableOpacity>

        {showMore ?
          <>

            <View
              style={{
                width: '100%',
                height: 1,
                backgroundColor: colors.neutral[300],
                marginVertical: 24,
              }}
            />
            {isEdit ? <ODSButton onPress={() => onEdit()} type='secondary' rIcon={'TB-Edit'} restStyle={{ marginTop: 10, zIndex: -10, alignSelf: 'flex-end', width: 140 }}>Edit</ODSButton> : null
            }
            {children}
            {isDelete ? <ODSButton
              themeColor={colors.main.salmon}
              onPress={() => onDelete()} type='secondary'
              rIcon={'TB-Trash'}
              restStyle={{ marginTop: 10, alignSelf: 'flex-end', width: 140 }}>Remove</ODSButton>
              : null}
          </>
          : null}
      </View>
    )
  }

  const renderTlyfePro = () => {
    const [showMore, setShowMore] = React.useState(false);
    return (
      <>
        <RenderAccorDian
          isImage={true}
          // imageStyle={{ width: 60, height: 34 }}
          imageURI={require("./../../../../images/logos/tlyfepro.png")}
        >
          {renderLabels("Property Address", "Creative Street")}
          {renderLabels("Property Postcode", "QTR234")}
          {renderLabels("Rental Start", "8th Nov 2023")}
          {renderLabels("Expected End Date", "8th Nov 2024")}
          {renderLabels("Rental Amount", "£2000")}
          {renderLabels("Deposit Amount", "£230.76")}
        </RenderAccorDian >
      </>
    )
  }
  const renderTds = () => {
    const [showMore, setShowMore] = React.useState(false);
    return (
      <>
        <RenderAccorDian
          isImage={true}
          // imageStyle={{ width: 93, height: 39 }}
          imageURI={require("./../../../../images/logos/TDSLOGO.png")}
          isEdit
          isDelete
          onEdit={() => { }}
          onDelete={() => { }}
        >
          {renderLabels("Property Address", "Creative Street")}
          {renderLabels("Property Postcode", "QTR234")}
          {renderLabels("Rental Start", "8th Nov 2023")}
          {renderLabels("Expected End Date", "8th Nov 2024")}
          {renderLabels("Rental Amount", "£2000")}
          {renderLabels("Deposit Amount", "£230.76")}
        </RenderAccorDian>
      </>
    )
  }
  const renderrentInfor = (data, index) => {
    console.log("renderrentInfor", data)
    return (
      <>
        <RenderAccorDian
          isImage={data?.source_logo}
          headerName={"My Rental Info " + (index + 1)}
          isEdit={data?.can_delete ? true : false}
          isDelete={data?.can_delete ? true : false}
          onEdit={() => {
            setModal({ visible: true, data: data, type: "edit" })
          }}
          onDelete={() => { onDeletePress(data.rental_profile_id) }}
        >
          {renderLabels("Property Address", data?.property_address ? data?.property_address : "")}
          {renderLabels("Property Postcode", data?.property_post_code ? data?.property_post_code : "")}
          {renderLabels("Rental Start", data?.rental_start_date ? moment(data?.rental_start_date).format('Do MMM YYYY') : "")}
          {renderLabels("Expected End Date", data?.expected_end_date ? moment(data?.expected_end_date).format('Do MMM YYYY') : "")}
          {renderLabels("Rental Amount", data?.rental_amount ? "£ " + data?.rental_amount : "")}
          {renderLabels("Deposit Amount", data?.deposit_amount ? "£ " + data?.deposit_amount : "")}
        </RenderAccorDian>

      </>
    )
  }
  return (
    <View style={{ flex: 1, ...styles.shadowBox }}>
      <View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginBottom: 32 }}>
        <ODSIcon icon={'TB-Profile-Info'} size={24} color={colors.button.main} />
        <ODSText type='h5' marginLeft={12} color={colors.text.primary}>My Rental Information</ODSText>
      </View>
      {/* {renderTlyfePro()}
      <View style={{ marginVertical: 10 }} />
      {renderTds()} */}
      {form && form.length ? form.map((item, index) =>
        <React.Fragment key={index}>
          <View style={{ marginVertical: 10 }} />
          {renderrentInfor(item, index)}
        </React.Fragment>
      ) : <><ODSText textAlign='center' marginTop={4} type='md-bold' color={colors.text.primary}>No information found!</ODSText>
        <ODSText textAlign='center' marginTop={8} type='md' color={colors.text.secondary}>Click on add button to add information</ODSText>
      </>}
      {modal.visible ?
        <MyRentalInfoModal
          visible={modal.visible}
          type={modal.type}
          modalData={modal.data}
          onPress={(data) => {
            console.log("data form modal", data)
            onAddRentalInfo(data);
            setModal({ ...modal, visible: false })
          }}
          onEditPress={(data) => {
            console.log("data form modal", data)
            onEditPress(data);
            setModal({ ...modal, visible: false })
          }}
          onClose={() => {
            setModal({ ...modal, visible: false })
          }}
        /> : null
      }
      <ODSButton onPress={() => setModal({ visible: true, data: {}, type: "add" })} type='primary' rIcon={'Frame'} restStyle={{ marginTop: 32, zIndex: -10, alignSelf: 'flex-end', width: 140 }}>Add</ODSButton>
    </View>
  );
};

const styles = StyleSheet.create({
  shadowBox: {
    backgroundColor: '#fff',
    marginHorizontal: 20,
    padding: 32,
    borderRadius: 12,
    ...Platform.select({
      ios: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      },
      android: {
        elevation: 4,
      },
      web: {
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 3 },
        shadowOpacity: 0.15,
        shadowRadius: 8,
      }
    }),
  },
});

export default MyRentalInfo;
